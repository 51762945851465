.App h1 {
  color: #eee;
}

#logo {
  height: 1.3em;
  background-color: white;
  padding: 5px;
  margin-right: 0.5em;
  vertical-align: sub;
}

.site-layout {
  padding: 0 50px;
  margin-top: 32px;
  text-align: center;
}

.question-card {
  background: #fff;
  padding: 24px;
  min-height: 200px;
  min-width: 220px;
  margin: 0 auto;
  text-align: center;
}

.question-card button.answer {
  width: 100%;
  height: 6em;
}

.question-card button.action {
  width: 100%;
  height: 4em;
}

body {
  background-color: #f0f2f5;
}

@media (max-height: 400px) {
  header.ant-layout-header {
    /* display: none; */
    font-size: 80%;
    height: 50px;
    line-height: 50px;
  }

  .site-layout {
    margin-top: 16px;
  }
  
  .ant-layout-footer {
    padding: 12px;
  }
}